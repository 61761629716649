<template>
  <a-modal
    v-model="showModal"
    :title="selectedTemplate ? 'Edit template' : 'Add new template'"
    :footer="null"
    width="700px"
    :destroyOnClose="true"
  >
    <a-form-model class="" ref="templateForm" :model="form" :rules="rules">
      <a-form-model-item label="Template name" prop="name" :colon="false">
        <a-input
          v-model="form.name"
          placeholder="Template name"
          size="large"
          :disabled="loading"
        />
      </a-form-model-item>
      <a-form-model-item label="Source" prop="source" :colon="false">
        <a-input
          v-model="form.source"
          placeholder="Source"
          size="large"
          :disabled="loading"
        />
      </a-form-model-item>
      <a-row class="footer-viewscreen">
        <a-col class="text-right" :span="24">
          <a-button
            v-if="selectedTemplate"
            type="danger"
            @click="deleteTemplate"
          >
            Delete
          </a-button>
          <a-button
            type="primary"
            class="ml-2"
            :loading="loading"
            @click="onSubmit"
          >
            {{ selectedTemplate ? 'Save' : 'Create' }}
          </a-button>
        </a-col>
      </a-row>
    </a-form-model>
  </a-modal>
</template>

<script>
import apiClient from '@/services/axios'
import { getErrorMessage } from '@/views/apps/fortune-wheel/composables/useGetErrorMessage.js'

const wheelApiUrl = process.env.VUE_APP_WHEEL_API_URL

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    selectedTemplate: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    showModal: false,
    form: {
      name: '',
      source: '',
    },
    rules: {
      name: [
        {
          required: true,
          message: 'Name is required',
          trigger: ['blur', 'change'],
        },
      ],
      source: [
        {
          required: true,
          message: 'Source is required',
          trigger: ['blur', 'change'],
        },
      ],
    },
    loading: false,
  }),
  watch: {
    show: {
      handler(val) {
        if (val) {
          this.showModal = true
        } else {
          this.showModal = false
        }
      },
      deep: true,
      immediate: true,
    },
    showModal: {
      handler(val) {
        if (!val) {
          this.closeModal()
        }
      },
      deep: true,
    },
  },
  methods: {
    templateDataFilling() {
      this.form.name = this.selectedTemplate.name
      this.form.source = this.selectedTemplate.source
    },
    bringToAUnifiedTemplateStructure(template) {
      return {
        name: template.name,
        value: template.id,
        source: template.source,
      }
    },
    onSubmit() {
      this.$refs.templateForm.validate(async (valid) => {
        if (valid) {
          this.loading = true
          let response
          if (this.selectedTemplate) {
            response = await this.save()
          } else {
            response = await this.create()
          }
          if (response?.data?.success) {
            const template = this.bringToAUnifiedTemplateStructure(response.data.data)
            if (this.selectedTemplate) {
              this.$emit('edit', template)
            } else {
              this.$emit('create', template)
            }
            this.closeModal()
          } else {
            getErrorMessage(response?.data)
          }
        } else {
          return false
        }
      })
    },
    async save() {
      try {
        const url = `${wheelApiUrl}/admin/source_templates/${this.selectedTemplate.value}`
        return await apiClient.put(url, this.form)
      } catch (e) {
        this.$notification.success({
          message: e?.message ? e.message : 'Error',
        })
      } finally {
        this.loading = false
      }
    },
    async create() {
      try {
        const url = `${wheelApiUrl}/admin/source_templates`
        return await apiClient.post(url, this.form)
      } catch (e) {
        this.$notification.success({
          message: e?.message ? e.message : 'Error',
        })
      } finally {
        this.loading = false
      }
    },
    async deleteTemplate() {
      try {
        this.loading = true
        const url = `${wheelApiUrl}/admin/source_templates/${this.selectedTemplate.value}`
        const response = await apiClient.delete(url)
        if (response?.data?.success) {
          this.$emit('delete', this.selectedTemplate.value)
          this.closeModal()
        } else {
          getErrorMessage(response?.data)
        }
      } catch (e) {
        this.$notification.success({
          message: e?.message ? e.message : 'Error',
        })
      } finally {
        this.loading = false
      }
    },
    closeModal() {
      this.$emit('close')
    },
  },
  mounted() {
    if (this.selectedTemplate) {
      this.templateDataFilling()
    }
  },
}
</script>
